
import React from 'react'
// import React from 'react'
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export default ({ image, proportional, forcedAspectRatio }) => {

    if(!image.mediaDetails.sizes) return false

    const sizes = image.mediaDetails.sizes
    const alt = image.altText || ""
    const sources = sizes.map(size => `${size.sourceUrl} ${size.width}w`)
    const srcset = sources.join(', ')

    const aspectRatio = proportional ? image.mediaDetails.width/image.mediaDetails.height : false;
    let padding = false;
    if(proportional){
        padding = `${1/aspectRatio*100}%`
    }else if(forcedAspectRatio){
        padding = `${1/forcedAspectRatio*100}%`
    }
    

    return (
        <div className="image__container" style={{ paddingBottom: padding, position: "relative" }}>
        {/* <div className="image__container" style={{ paddingBottom: vh ? false : padding }}> */}
            <div className="gallery__post__image" >
                <img 
                    data-sizes="auto"
                    data-expand="1000"
                    data-srcset={srcset}
                    alt={alt}
                    className="lazyload lazypreload"
                    // style={{ width, height }}
                />
            </div>
            <style >{`
                .swiper-slide{
                    overflow: hidden;
                }
                .swiper-lazy, .lazyload{
                    transition: filter 0.3s;
                    filter: blur(4vh);
                    opacity: 0;
                    transition: opacity 0.3s;
                }
                .swiper-lazy-loaded, .lazyloaded{
                    filter: blur(0);
                    transition: opacity 0.3s;
                    opacity: 1;
                }
                .swiper-slide-active div{
                    transition: opacity .3s;
                    opacity: 1;
                }
                .image__container{
                    font-size: 0;
                    overflow: hidden;
                    background: gainsboro;
                }
                img{
                    margin-bottom: -10px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                }
            `}</style>
        </div>
    )
}